import React from 'react';
import { Link } from 'react-router-dom';

import Header from "../../components/Header";

function Blog(props) {
    return (
      
      <>
        <Header navBar="default" />

        {/* Breadcrumb */}
        <nav className="container mt-lg-4 pt-5" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 pt-5">
            <li className="breadcrumb-item">
              <a href="index-2.html"><i className="bx bx-home-alt fs-lg me-1" />Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Blog List with Sidebar</li>
          </ol>
        </nav>

        {/* Page content */}
        <section className="container mt-4 mb-2 mb-md-4 mb-lg-5 pt-lg-2 pb-5">
          {/* Page title + Layout switcher */}
          <div className="d-flex align-items-center justify-content-between mb-4 pb-1 pb-md-3">
            <h1 className="mb-0">Blog List with Sidebar</h1>
            <div className="nav flex-nowrap ms-sm-4 ms-3">
              <a href="blog-list-with-sidebar.html" className="nav-link me-2 p-0 active" aria-label="List view">
                <i className="bx bx-list-ul fs-4" />
              </a>
              <a href="blog-grid-with-sidebar.html" className="nav-link p-0" aria-label="Grid view">
                <i className="bx bx-grid-alt fs-4" />
              </a>
            </div>
          </div>
          {/* Blog list + Sidebar */}
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              {/* Item */}
              <article className="card border-0 bg-transparent me-xl-5 mb-4">
                <div className="row g-0">
                  <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3" style={{backgroundImage: 'url(assets/img/blog/05.jpg)', minHeight: '15rem'}}>
                    <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <div className="col-sm-7">
                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                      <a href="#" className="badge fs-sm text-white bg-info shadow-info text-decoration-none mb-3">Digital</a>
                      <h3 className="h4">
                        <a href="blog-single.html">Inclusive Marketing: Why and How Does it Work?</a>
                      </h3>
                      <p className="mb-4">Nunc aliquet scelerisque pellentesque imperdiet tortor elit, dictum. Tristique odio at dignissim viverra aliquet eleifend erat. Tellus, at arcu, egestas praesent.</p>
                      <div className="d-flex align-items-center text-muted">
                        <div className="fs-sm border-end pe-3 me-3">Oct 9, 2023</div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-like fs-lg me-1" />
                          <span className="fs-sm">5</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-comment fs-lg me-1" />
                          <span className="fs-sm">0</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="bx bx-share-alt fs-lg me-1" />
                          <span className="fs-sm">2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card me-xl-5 mb-4">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-3">
                    <a href="#" className="badge fs-sm text-white bg-warning shadow-warning text-decoration-none">Business</a>
                    <a href="#" className="btn btn-icon btn-secondary btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <h3 className="h4">
                    <a href="blog-single.html">A Study on Smartwatch Design Qualities and People’s Preferences</a>
                  </h3>
                  <p className="mb-4">Sed parturient in faucibus porttitor morbi rutrum in. Amet fermentum, scelerisque suspendisse nisi faucibus quis lacus. Convallis eget pretium leo, egestas nisl, congue quam et ultrices. Pharetra, elementum pulvinar massa mattis sapien, sit. Id sollicitudin viverra morbi vitae ac tempor bibendum pulvinar vel. Integer non mattis ultrices est morbi ullamcorper scelerisque hac mi. Id semper ut dui sit magna.</p>
                  <div className="d-flex align-items-center text-muted">
                    <div className="fs-sm border-end pe-3 me-3">Sep 3, 2023</div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-like fs-lg me-1" />
                      <span className="fs-sm">8</span>
                    </div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-comment fs-lg me-1" />
                      <span className="fs-sm">7</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-share-alt fs-lg me-1" />
                      <span className="fs-sm">3</span>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card border-0 bg-transparent me-xl-5 mb-4">
                <div className="row g-0">
                  <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3" style={{backgroundImage: 'url(assets/img/blog/03.jpg)', minHeight: '15rem'}}>
                    <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <div className="col-sm-7">
                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                      <a href="#" className="badge fs-sm text-white bg-danger shadow-danger text-decoration-none mb-3">Technology</a>
                      <h3 className="h4">
                        <a href="blog-single.html">This Week in Search: New Limits and Exciting Features</a>
                      </h3>
                      <p className="mb-4">Aliquet donec cras amet orci in in neque. Ut sed diam quis consectetur purus lorem eu, sit. Aliquam eget arcu sed urna feugiat. In integer nisl at dui malesuada. Diam pellentesque lobortis elementum lacus.</p>
                      <div className="d-flex align-items-center text-muted">
                        <div className="fs-sm border-end pe-3 me-3">Sep 16, 2023</div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-like fs-lg me-1" />
                          <span className="fs-sm">5</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-comment fs-lg me-1" />
                          <span className="fs-sm">7</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="bx bx-share-alt fs-lg me-1" />
                          <span className="fs-sm">4</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card me-xl-5 mb-4">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-3">
                    <a href="#" className="badge fs-sm text-white bg-success shadow-success text-decoration-none">Startups</a>
                    <a href="#" className="btn btn-icon btn-secondary btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <h3 className="h4">
                    <a href="blog-single.html">How Agile is Your Forecasting Process?</a>
                  </h3>
                  <p className="mb-4">Nunc aliquet scelerisque pellentesque imperdiet tortor elit, dictum. Tristique odio at dignissim viverra aliquet eleifend erat. Tellus, at arcu, egestas praesent. Varius aliquet pharetra adipiscing tincidunt orci nec neque.</p>
                  <div className="d-flex align-items-center text-muted">
                    <div className="fs-sm border-end pe-3 me-3">Sep 10, 2023</div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-like fs-lg me-1" />
                      <span className="fs-sm">3</span>
                    </div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-comment fs-lg me-1" />
                      <span className="fs-sm">6</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-share-alt fs-lg me-1" />
                      <span className="fs-sm">0</span>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card border-0 bg-transparent me-xl-5 mb-4">
                <div className="row g-0">
                  <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3" style={{backgroundImage: 'url(assets/img/blog/02.jpg)', minHeight: '15rem'}}>
                    <a href="blog-single.html" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <div className="col-sm-7">
                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                      <a href="#" className="badge fs-sm text-white bg-warning shadow-warning text-decoration-none mb-3">Business</a>
                      <h3 className="h4">
                        <a href="blog-single.html">Why UX Design Matters and How it Affects Ranking</a>
                      </h3>
                      <p className="mb-4">In mauris porttitor tincidunt mauris massa sit lorem sed scelerisque. Fringilla pharetra vel massa enim sollicitudin cras. At pulvinar eget sociis adipiscing eget donec ultricies nibh tristique.</p>
                      <div className="d-flex align-items-center text-muted">
                        <div className="fs-sm border-end pe-3 me-3">Aug 19, 2023</div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-like fs-lg me-1" />
                          <span className="fs-sm">5</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-comment fs-lg me-1" />
                          <span className="fs-sm">3</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="bx bx-share-alt fs-lg me-1" />
                          <span className="fs-sm">9</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card me-xl-5 mb-4">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-3">
                    <a href="#" className="badge fs-sm text-white bg-info shadow-info text-decoration-none">Digital</a>
                    <a href="#" className="btn btn-icon btn-secondary btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <h3 className="h4">
                    <a href="blog-single.html">This Long-Awaited Technology May Finally Change the World</a>
                  </h3>
                  <p className="mb-4">Nunc, enim donec venenatis quis amet, magna vulputate rhoncus. Interdum lacus, nam elementum, cursus felis cras eros vel tincidunt. Felis nunc rhoncus quis curabitur facilisi. Odio cursus in vel et pretium. Ornare interdum in cras nibh lectus fusce. Neque, donec nunc, tellus, bibendum. Id euismod urna diam dolor lobortis consectetu.</p>
                  <div className="d-flex align-items-center text-muted">
                    <div className="fs-sm border-end pe-3 me-3">Sep 3, 2023</div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-like fs-lg me-1" />
                      <span className="fs-sm">0</span>
                    </div>
                    <div className="d-flex align-items-center me-3">
                      <i className="bx bx-comment fs-lg me-1" />
                      <span className="fs-sm">1</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-share-alt fs-lg me-1" />
                      <span className="fs-sm">2</span>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Item */}
              <article className="card border-0 bg-transparent me-xl-5">
                <div className="row g-0">
                  <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover rounded-3 overflow-hidden" style={{backgroundImage: 'url(assets/img/blog/01.jpg)', minHeight: '15rem'}}>
                    <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35" />
                    <div className="d-flex align-items-center justify-content-center position-absolute top-0 start-0 w-100 h-100 zindex-2">
                      <a href="https://www.youtube.com/watch?v=vTgKtoU--Z8" className="btn btn-video btn-icon btn-lg bg-white stretched-link" data-bs-toggle="video" aria-label="Play video">
                        <i className="bx bx-play" />
                      </a>
                    </div>
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Read later" aria-label="Read later">
                      <i className="bx bx-bookmark" />
                    </a>
                  </div>
                  <div className="col-sm-7">
                    <div className="card-body px-0 pt-sm-0 ps-sm-4 pb-0 pb-sm-4">
                      <a href="#" className="badge fs-sm text-white bg-success shadow-success text-decoration-none mb-3">Startups</a>
                      <h3 className="h4">
                        <a href="blog-single.html">5 Bad Landing Page Examples &amp; How We Would Fix Them</a>
                      </h3>
                      <p className="mb-4">Nunc aliquet scelerisque pellentesque imperdiet tortor elit, dictum. Tristique odio at dignissim viverra aliquet eleifend erat. Tellus, at arcu, egestas praesent.</p>
                      <div className="d-flex align-items-center text-muted">
                        <div className="fs-sm border-end pe-3 me-3">Sep 3, 2023</div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-like fs-lg me-1" />
                          <span className="fs-sm">8</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                          <i className="bx bx-comment fs-lg me-1" />
                          <span className="fs-sm">7</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="bx bx-share-alt fs-lg me-1" />
                          <span className="fs-sm">3</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className="pb-2 pb-lg-3" />
              {/* Load more button */}
              <button type="button" className="btn btn-lg btn-outline-primary w-100 mt-4">
                <i className="bx bx-down-arrow-alt fs-xl me-2" />
                Show more
              </button>
            </div>
            {/* Sidebar (Offcanvas below lg breakpoint) */}
            <aside className="col-xl-3 col-lg-4">
              <div className="offcanvas-lg offcanvas-end" id="blog-sidebar" tabIndex={-1}>
                {/* Header */}
                <div className="offcanvas-header border-bottom">
                  <h3 className="offcanvas-title fs-lg">Sidebar</h3>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#blog-sidebar" aria-label="Close" />
                </div>
                {/* Body */}
                <div className="offcanvas-body">
                  {/* Search form */}
                  <form className="input-group mb-4">
                    <input type="text" placeholder="Search the blog..." className="form-control rounded pe-5" />
                    <i className="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 fs-lg zindex-5" />
                  </form>
                  {/* Categories */}
                  <div className="card card-body mb-4">
                    <h3 className="h5">Categories</h3>
                    <ul className="nav flex-column fs-sm">
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0 active">All topics <span className="fw-normal opacity-60 ms-1">(48)</span></a>
                      </li>
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0">Digital <span className="fw-normal opacity-60 ms-1">(12)</span></a>
                      </li>
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0">Marketing <span className="fw-normal opacity-60 ms-1">(5)</span></a>
                      </li>
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0">Startups <span className="fw-normal opacity-60 ms-1">(10)</span></a>
                      </li>
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0">Technology <span className="fw-normal opacity-60 ms-1">(9)</span></a>
                      </li>
                      <li className="nav-item mb-1">
                        <a href="#" className="nav-link py-1 px-0">Business <span className="fw-normal opacity-60 ms-1">(4)</span></a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link py-1 px-0">Processes &amp; Tools <span className="fw-normal opacity-60 ms-1">(3)</span></a>
                      </li>
                    </ul>
                  </div>
                  {/* Popular posts */}
                  <div className="card card-body border-0 position-relative mb-4">
                    <span className="position-absolute top-0 start-0 w-100 h-100 bg-gradient-primary opacity-10 rounded-3" />
                    <div className="position-relative zindex-2">
                      <h3 className="h5">Popular posts</h3>
                      <ul className="list-unstyled mb-0">
                        <li className="border-bottom pb-3 mb-3">
                          <h4 className="h6 mb-2">
                            <a href="#">5 Bad Landing Page Examples &amp; How We Would Fix Them</a>
                          </h4>
                          <div className="d-flex align-items-center text-muted pt-1">
                            <div className="fs-xs border-end pe-3 me-3">12 hours ago</div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-base me-1" />
                              <span className="fs-xs">8</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-base me-1" />
                              <span className="fs-xs">4</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-base me-1" />
                              <span className="fs-xs">2</span>
                            </div>
                          </div>
                        </li>
                        <li className="border-bottom pb-3 mb-3">
                          <h4 className="h6 mb-2">
                            <a href="#">How Agile is Your Forecasting Process?</a>
                          </h4>
                          <div className="d-flex align-items-center text-muted pt-1">
                            <div className="fs-xs border-end pe-3 me-3">Oct 9, 2023</div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-base me-1" />
                              <span className="fs-xs">4</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-base me-1" />
                              <span className="fs-xs">1</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-base me-1" />
                              <span className="fs-xs">0</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <h4 className="h6 mb-2">
                            <a href="#">Inclusive Marketing: Why and How Does it Work?</a>
                          </h4>
                          <div className="d-flex align-items-center text-muted pt-1">
                            <div className="fs-xs border-end pe-3 me-3">Sep 13, 2023</div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-like fs-base me-1" />
                              <span className="fs-xs">5</span>
                            </div>
                            <div className="d-flex align-items-center me-3">
                              <i className="bx bx-comment fs-base me-1" />
                              <span className="fs-xs">2</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="bx bx-share-alt fs-base me-1" />
                              <span className="fs-xs">4</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Tags */}
                  <div className="card card-body mb-4">
                    <h3 className="h5">Tags</h3>
                    <div className="d-flex flex-wrap">
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#lifestyle</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#ux</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#tech</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#user</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#software</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#it</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#network</a>
                      <a href="#" className="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#engineering</a>
                    </div>
                  </div>
                  {/* Follow Us */}
                  <div className="card mb-4">
                    <div className="card-body">
                      <h5 className="mb-4">Follow Us</h5>
                      <a href="#" className="btn btn-icon btn-sm btn-secondary btn-linkedin me-2 mb-2" aria-label="LinkedIn">
                        <i className="bx bxl-linkedin" />
                      </a>
                      <a href="#" className="btn btn-icon btn-sm btn-secondary btn-facebook me-2 mb-2" aria-label="Facebook">
                        <i className="bx bxl-facebook" />
                      </a>
                      <a href="#" className="btn btn-icon btn-sm btn-secondary btn-twitter me-2 mb-2" aria-label="Twitter">
                        <i className="bx bxl-twitter" />
                      </a>
                      <a href="#" className="btn btn-icon btn-sm btn-secondary btn-instagram me-2 mb-2" aria-label="Instagram">
                        <i className="bx bxl-instagram" />
                      </a>
                    </div>
                  </div>
                  {/* Advertising */}
                  <div className="card border-0 bg-faded-primary bg-repeat-0 bg-size-cover" style={{minHeight: '25rem', backgroundImage: 'url(assets/img/blog/banner.png)'}}>
                    <div className="card-body">
                      <h5 className="h3 mb-4 pb-2 text-center">Ad Banner</h5>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>

        {/* Subscription CTA */}
        <section className="py-5 bg-secondary">
          <div className="container py-md-3 py-lg-5">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9 col-md-11">
                <h2 className="h1 d-md-inline-block position-relative mb-md-5 mb-sm-4 text-sm-start text-center">
                  Don't Want to Miss Anything?
                  {/* Arrow shape */}
                  <svg className="d-md-block d-none position-absolute top-0 ms-4 ps-1" style={{left: '100%'}} xmlns="http://www.w3.org/2000/svg" width={65} height={68} fill="#6366f1"><path d="M53.9527 51.0012c8.396-10.5668 2.0302-26.0134-11.7481-26.7511-.6899-.0646-1.4612.0015-2.1258.0431.1243 9.0462-4.1714 18.8896-11.5618 21.3814-6.6695 2.2133-10.3337-4.2224-7.5813-9.676 3.2966-6.4755 9.103-11.8504 16.1678-13.8189-.5654-5.6953-3.3436-10.7672-9.485-12.48517C17.2678 6.8204 6.49364 16.3681 4.98841 26.127c-.09276 1.0297-1.68569.9497-1.59293-.0801C3.98732 12.9139 19.7395 2.55212 31.9628 8.5787c4.7253 2.3813 7.2649 7.3963 7.9368 13.067 7.4237-.9311 14.5154 3.3683 18.3422 9.5422 4.3988 7.1623 2.3584 15.1401-2.6322 21.1108-.7826.9653-2.3331-.3572-1.6569-1.2975zM26.7754 32.1845c-1.9411 2.2411-4.076 5.0872-4.3542 8.1764-.3036 2.9829 3.7601 3.0525 5.4905 2.7645 2.1568-.3863 3.7221-2.3164 4.8863-4.0419 2.6228-3.6308 4.3657-9.0752 4.4844-14.2563-4.0808 1.279-7.6514 4.2327-10.507 7.3573zm24.6311 25.592c-.7061-2.9738-1.2243-6.1031-1.1591-9.143.0423-1.242 1.767-1.0805 1.8313.1372.1284 2.435.815 4.8532 1.4764 7.1651l4.1619-1.4098c1.0153-.4586 2.4373-1.5714 3.6544-1.1804.6087.1954.7347.7264.6475 1.3068-.2302 1.3976-2.4683 1.9147-3.5901 2.398-1.8429.7619-3.6293 1.2865-5.5477 1.7298-.6391.1476-1.3233-.3665-1.4746-1.0037z" /></svg>
                </h2>
                {/* Title + checkboxes */}
                <div className="row gy-4 align-items-center mb-lg-5 mb-4 pb-3">
                  <div className="col-md-3">
                    <h3 className="h5 mb-0 text-sm-start text-center">Sign up for Newsletters</h3>
                  </div>
                  <div className="col-md-9">
                    <div className="row row-cols-sm-3 row-cols-2 gy-2">
                      <div className="col">
                        <div className="form-check mb-0">
                          <input type="checkbox" id="s-daily-newsletter" className="form-check-input" />
                          <label htmlFor="s-daily-newsletter" className="form-check-label">Daily Newsletter</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check mb-0">
                          <input type="checkbox" id="s-advertising-updates" className="form-check-input" defaultChecked />
                          <label htmlFor="s-advertising-updates" className="form-check-label">Advertising Updates</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check mb-0">
                          <input type="checkbox" id="s-week-in-review" className="form-check-input" />
                          <label htmlFor="s-week-in-review" className="form-check-label">Week in Review</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check mb-0">
                          <input type="checkbox" id="s-event-updates" className="form-check-input" />
                          <label htmlFor="s-event-updates" className="form-check-label">Event Updates</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check mb-0">
                          <input type="checkbox" id="s-startups-weekly" className="form-check-input" />
                          <label htmlFor="s-startups-weekly" className="form-check-label">Startups Weekly</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check mb-0">
                          <input id="s-podcasts" type="checkbox" className="form-check-input" />
                          <label htmlFor="s-podcasts" className="form-check-label">Podcasts</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Email field */}
                <form className="d-flex flex-sm-row flex-column mb-3 needs-validation" noValidate>
                  <div className="input-group me-sm-3 mb-sm-0 mb-3">
                    <input type="email" className="form-control form-control-lg rounded-3 ps-5" placeholder="Your email" required />
                    <i className="bx bx-envelope position-absolute start-0 top-50 translate-middle-y ms-3 zindex-5 fs-5 text-muted" />
                    <div className="invalid-tooltip position-absolute start-0 top-0 mt-n4">Please provide a valid email address!</div>
                  </div>
                  <button type="submit" className="btn btn-lg btn-primary">Subscribe *</button>
                </form>
                <div className="form-text fs-sm text-sm-start text-center">
                  * Yes, I agree to the <a href="#">terms</a> and <a href="#">privacy policy</a>.
                </div>
              </div>
            </div>
          </div>
        </section>

      </>

    );
}

export default Blog;