import { useEffect } from "react";

import Header from "../../components/Header";
import { Link } from "react-router-dom";


function Home(props) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/vendor/jarallax/dist/jarallax.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/vendor/swiper/swiper-bundle.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/js/theme.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>

      <Header navBar="default" />

      {/* Hero slider + BG parallax */}
      <section className="jarallax bg-dark py-xxl-5" data-jarallax data-speed="0.4" data-bs-theme="dark">
        <span className="position-absolute top-0 start-0 w-100 h-100 bg-gradient-dark-translucent" />
        <div className="jarallax-img" style={{backgroundImage: 'url(/assets/img/landing/software-agency-1/hero-bg.jpg)'}} />
        <div className="position-relative text-center zindex-5 overflow-hidden pt-4 py-md-5">
          {/* Slider controls (Prev / next) */}
          <button type="button" id="hero-prev" className="btn btn-prev btn-icon btn-xl bg-transparent shadow-none position-absolute top-50 start-0 translate-middle-y d-none d-md-inline-flex ms-n3 ms-lg-2" aria-label="Previous">
            <i className="bx bx-chevron-left fs-1" />
          </button>
          <button type="button" id="hero-next" className="btn btn-next btn-icon btn-xl bg-transparent shadow-none position-absolute top-50 end-0 translate-middle-y d-none d-md-inline-flex me-n3 me-lg-2" aria-label="Next">
            <i className="bx bx-chevron-right fs-1" />
          </button>
          {/* Slider */}
          <div className="container text-center py-xl-5">
            <div className="row justify-content-center pt-lg-5">
              <div className="col-xl-8 col-lg-9 col-md-10 col-11">
                <div className="swiper pt-5 pb-4 py-md-5" data-swiper-options="{
                  &quot;effect&quot;: &quot;fade&quot;,
                  &quot;speed&quot;: 500,
                  &quot;autoplay&quot;: {
                    &quot;delay&quot;: 5500,
                    &quot;disableOnInteraction&quot;: false
                  },
                  &quot;pagination&quot;: {
                    &quot;el&quot;: &quot;.swiper-pagination&quot;,
                    &quot;clickable&quot;: true
                  },
                  &quot;navigation&quot;: {
                    &quot;prevEl&quot;: &quot;#hero-prev&quot;,
                    &quot;nextEl&quot;: &quot;#hero-next&quot;
                  }
                }">
                  <div className="swiper-wrapper">
                    {/* Item */}
                    <div className="swiper-slide">
                      <h2 className="display-2 from-start mb-lg-4">Votre avenir numérique commence ici</h2>
                      <div className="from-end">
                        <p className="fs-xl text-light opacity-70 pb-2 mb-lg-5">Découvrez dès aujourd'hui l'avenir numérique avec nos solutions 
                        innovantes, redéfinissant les standards 
                        de l'industrie grâce à nos systèmes d'information sur mesure.</p>
                      </div>
                      <div className="scale-up delay-1">
                        <Link to="#" className="btn btn-primary shadow-primary btn-lg">Découverte des solutions</Link>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="swiper-slide">
                      <h2 className="display-2 from-start mb-lg-4">Du Croquis à la Réalité Digitale</h2>
                      <div className="from-end">
                        <p className="fs-xl text-light opacity-70 pb-2 mb-lg-5">Suivez notre parcours fascinant où chaque idée prend vie, 
                        de la conception initiale à la concrétisation numérique.</p>
                      </div>
                      <div className="scale-up delay-1">
                        <Link to="#" className="btn btn-primary shadow-primary btn-lg">Commençons un projet ensemble</Link>
                      </div>
                    </div>
                    {/* Item */}
                    <div className="swiper-slide">
                      <h2 className="display-2 from-start mb-lg-4">Protection sans Compromis</h2>
                      <div className="from-end">
                        <p className="fs-xl text-light opacity-70 pb-2 mb-lg-5">Nos solutions intégrées offrent une défense complète contre les menaces numériques, garantissant la tranquillité d'esprit de votre organisation.</p>
                      </div>
                      <div className="scale-up delay-1">
                        <Link to="#" className="btn btn-primary shadow-primary btn-lg">Solutions PME</Link>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <h2 className="display-2 from-start mb-lg-4">Capturons l'Éclat de Votre Événement</h2>
                      <div className="from-end">
                        <p className="fs-xl text-light opacity-70 pb-2 mb-lg-5">Des moments spontanés aux instants précieux, nous immortalisons chaque 
                        détail.</p>
                      </div>
                      <div className="scale-up delay-1">
                        <Link to="#" className="btn btn-primary shadow-primary btn-lg">Nos différents offres</Link>
                      </div>
                    </div>
                  </div>
                  {/* Pagination (bullets) */}
                  <div className="swiper-pagination position-relative d-md-none pt-2 mt-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-column flex-sm-row align-items-center justify-content-between position-relative zindex-5 pb-5">
          <div className="d-flex mb-4 mb-sm-0">
            <Link to="https://www.linkedin.com/company/asnec-it/" target="_blank" className="btn btn-icon btn-secondary btn-linkedin rounded-circle me-2" aria-label="LinkedIn">
              <i className="bx bxl-linkedin" />
            </Link>
            <Link to="https://www.facebook.com/www.asnecit/" target="_blank" className="btn btn-icon btn-secondary btn-facebook rounded-circle me-2" aria-label="facebook">
              <i className="bx bxl-facebook" />
            </Link>
            <Link to="https://www.instagram.com/asnecit_/" target="_blank" className="btn btn-icon btn-secondary btn-instagram rounded-circle me-2" aria-label="instagram">
              <i className="bx bxl-instagram" />
            </Link>
            <Link to="https://www.facebook.com/Venteaucongo-106368739896802" target="_blank" className="btn btn-icon btn-secondary btn-facebook rounded-circle me-2" aria-label="Facebook">
              <i className="bx bxl-facebook" />
            </Link>
            <Link to="https://www.tiktok.com/@asnecit?_t=8kxQFhLrHug&_r=1" target="_blank" className="btn btn-icon btn-secondary btn-tiktok rounded-circle me-2" 
            aria-label="tiktok">
              <i className="bx bxl-tiktok" />
            </Link>
            <Link to="https://www.youtube.com/@asnecit5387" target="_blank" className="btn btn-icon btn-secondary btn-youtube rounded-circle me-2" aria-label="YouTube">
              <i className="bx bxl-youtube" />
            </Link>
          </div>
        </div>
      </section>

      {/* About */}
      <section className="container pt-5 mt-2 mt-md-4 mt-lg-5">
        <div className="row pt-xl-3">
          <div className="col-md-5 text-center text-md-start pb-5">
            <h1 className="mb-4">QUI SOMMES-NOUS</h1>
            <p style={{textAlign:"justify"}}  className="fs-lg pb-lg-3 mb-4">ASNEC-IT est une Agence de Services Numériques 
            (développement d’applications, community management, production visuelle…) 
            et d’E-commerce qui accompagne ses partenaires dans leur transformation numérique et digitale.</p>
            <p style={{textAlign:"justify"}} className="fs-lg pb-lg-3 mb-4">Notre accompagnement se fait en apportant des conseils stratégiques dans le cadre de 
            l’utilisation des outils informatiques, tout en étant à leurs écoutes, comprendre leurs besoins et développer ensemble 
            une solution sur mesure répondant à leurs attentes</p>
            <Link to="/about" className="btn btn-primary shadow-primary btn-lg"> Apropos de nous</Link>
          </div>
          <div className="col-xl-6 col-md-7 offset-xl-1 pb-4 pb-sm-3 pb-lg-0 mb-4 mb-sm-5 mb-lg-0">
            <img src="/assets/img/landing/software-agency-1/about-img.jpg" className="rounded-3 shadow-sm" alt="ASNEC-IT" />
          </div>
        </div>
      </section>

      {/* Services */}
      <section className="bg-secondary pb-md-2 pb-lg-5">
        <div className="d-none d-lg-block" style={{marginTop: '-60px', paddingTop: 60}} />
        <div className="container pb-4 pt-5">
          <h2 className="h1 text-center text-md-start mb-lg-4 pt-1 pt-md-4">Nos différents services</h2>
          <div className="row align-items-center pb-5 mb-lg-2">
            <div className="col-md-8 text-center text-md-start">
              <p className="fs-lg text-muted mb-md-0">We are focused on helping brands grow through digital transformation services. We bring real solutions to each client’s problems through a deep understanding of their market, solution, and vision.</p>
            </div>
            <div className="col-md-4 d-flex justify-content-center justify-content-md-end">
              <Link to="services-all" className="btn btn-outline-primary btn-lg">See all services</Link>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-web/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/cms.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    AUDIT ET CONSEIL
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Nisi, dis sed cursus eget pellentesque mattis. Odio eu proin aliquam a. Semper bibendum tellus non tellus, facilisi dignissim in quam massa. Aliquam, feugiat ut cum tellus, sit. Quis consectetur gravida ac ac lectus cursus egestas.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-web/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/cms.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    DEVELOPPEMENT D'APPLICATIONS
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Nisi, dis sed cursus eget pellentesque mattis. Odio eu proin aliquam a. Semper bibendum tellus non tellus, facilisi dignissim in quam massa. Aliquam, feugiat ut cum tellus, sit. Quis consectetur gravida ac ac lectus cursus egestas.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-mobil/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/rocket.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    SYSTEME ET RESEAUX INFORMATIQUES
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Id eget blandit sapien cras massa lectus lorem placerat. Quam dolor commodo fermentum bibendum dictumst. Risus pretium eget at viverra eget. Sit neque adipiscing malesuada blandit justo, quam.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/evenementiel/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/mobile-app.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    MARKETING DIGITAL
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Nunc, justo, diam orci, dictum purus convallis risus. Suscipit hendrerit at egestas id id blandit interdum est. Integer fames placerat turpis pretium quis hac leo lacus. Orci, dictum nunc mus quis semper eu bibendum enim, morbi.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-mobil/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/analytics.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    COUVERTURE EVENEMENTIEL
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Gravida eget euismod tempus diam dignissim quam. Dignissim magnis blandit faucibus convallis augue nisl, etiam. Feugiat ut molestie non arcu senectus sed. Diam pellentesque sit mattis nec amet varius nunc a sed.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-mobil/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/web-search.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    RENFORCEMENT DES CAPACITES
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Quis rhoncus quam venenatis facilisi. Risus dis libero nisl condimentum quis. Tincidunt ultricies vulputate ornare nunc rhoncus in. Ultrices dolor eu natoque volutpat praesent curabitur ultricies.</p>
                </div>
              </Link>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <Link to="/services/asnec/developpement-mobil/services-single" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/timer.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    COMMERCE EN DETAIL
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Massa dis morbi sagittis, tellus sit gravida. Id ut non ut in faucibus eu, ac. Tempus feugiat enim id pellentesque a sagittis vitae, convallis. Nunc, arcu enim orci ullamcorper aenean. Scelerisque eget a nibh bibendum commodo.</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Case studies */}
      <section className="bg-secondary pt-1">
        <div className="container">
          <h2 className="h1 text-center text-md-start mb-lg-4">Case Studies</h2>
          <div className="row align-items-center pb-5">
            <div className="col-md-8 col-lg-7 col-xl-6 text-center text-md-start">
              <p className="fs-lg text-muted mb-md-0">We bring real solutions to each client’s problems through a deep understanding of their market, solution, and vision.</p>
            </div>
            <div className="col-md-4 col-lg-5 col-xl-6 d-flex justify-content-center justify-content-md-end">
              <Link to="#" className="btn btn-outline-primary btn-lg">See all case studies</Link>
            </div>
          </div>
        </div>
        <div className="position-relative py-lg-4 py-xl-5">
          {/* Swiper tabs */}
          <div className="swiper-tabs position-absolute top-0 start-0 w-100 h-100">
            <div id="image-1" className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab active" data-jarallax data-speed="0.4">
              <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35" />
              <div className="jarallax-img" style={{backgroundImage: 'url(/assets/img/landing/software-agency-1/case-study01.jpg)'}} />
            </div>
            <div id="image-2" className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab" data-jarallax data-speed="0.4">
              <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35" />
              <div className="jarallax-img" style={{backgroundImage: 'url(/assets/img/landing/software-agency-1/case-study02.jpg)'}} />
            </div>
          </div>
          {/* Swiper slider */}
          <div className="container position-relative zindex-5 py-5">
            <div className="row py-2 py-md-3">
              <div className="col-xl-5 col-lg-7 col-md-9">
                {/* Slider controls (Prev / next) */}
                <div className="d-flex justify-content-center justify-content-md-start pb-3 mb-3">
                  <button type="button" id="case-study-prev" className="btn btn-prev btn-icon btn-sm bg-white me-2" aria-label="Previous">
                    <i className="bx bx-chevron-left" />
                  </button>
                  <button type="button" id="case-study-next" className="btn btn-next btn-icon btn-sm bg-white ms-2" aria-label="Next">
                    <i className="bx bx-chevron-right" />
                  </button>
                </div>
                {/* Card */}
                <div className="card bg-white shadow-sm p-3">
                  <div className="card-body">
                    <div className="swiper" data-swiper-options="{
                      &quot;spaceBetween&quot;: 30,
                      &quot;loop&quot;: true,
                      &quot;tabs&quot;: true,
                      &quot;pagination&quot;: {
                        &quot;el&quot;: &quot;#case-study-pagination&quot;,
                        &quot;clickable&quot;: true
                      },
                      &quot;navigation&quot;: {
                        &quot;prevEl&quot;: &quot;#case-study-prev&quot;,
                        &quot;nextEl&quot;: &quot;#case-study-next&quot;
                      }
                    }">
                      <div className="swiper-wrapper">
                        {/* Item */}
                        <div className="swiper-slide" data-swiper-tab="#image-1">
                          <img src="/assets/img/landing/software-agency-1/case-study-logo01.png" className="d-block mb-3" width={72} alt="Logo" />
                          <h3 className="mb-2">Cashless payment case study</h3>
                          <p className="fs-sm text-muted border-bottom pb-3 mb-3">Payment Service Provider Company</p>
                          <p className="pb-2 pb-lg-3 mb-3">Aenean dolor elit tempus tellus imperdiet. Elementum, ac convallis morbi sit est feugiat ultrices. Cras tortor maecenas pulvinar nec ac justo. Massa sem eget semper...</p>
                          <Link to="#" className="btn btn-primary">View case study</Link>
                        </div>
                        {/* Item */}
                        <div className="swiper-slide" data-swiper-tab="#image-2">
                          <img src="/assets/img/landing/software-agency-1/case-study-logo02.png" className="d-block mb-3" width={72} alt="Logo" />
                          <h3 className="mb-2">Smart tech case study</h3>
                          <p className="fs-sm text-muted border-bottom pb-3 mb-3">Data Analytics Company</p>
                          <p className="pb-2 pb-lg-3 mb-3">Adipiscing quis a at ligula. Gravida gravida diam rhoncus cursus in. Turpis sagittis tempor gravida phasellus sapien. Faucibus donec consectetur sed id sit nisl.</p>
                          <Link to="#" className="btn btn-primary">View case study</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pagination (bullets) */}
                <div className="pt-4 mt-3" data-bs-theme="dark">
                  <div id="case-study-pagination" className="swiper-pagination position-relative bottom-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="container py-5 my-2 my-md-4 my-lg-5">
        <h2 className="h1 text-center pt-1 pt-xl-2 mb-4">Why Choose Us?</h2>
        <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">We personalize how we work to fit your project needs. Our approach helps augment innovation.</p>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-xl-3">
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/01.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Build a&nbsp;functional prototype in&nbsp;24 hrs</h3>
          </div>
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/02.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Create an MVP within two weeks</h3>
          </div>
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/03.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Kickstart software development</h3>
          </div>
          <div className="col text-center">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/04.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Get 24/7 tech &amp; business support</h3>
          </div>
        </div>
      </section>

      {/* Testimonials (Slider) */}
      <section className="container pb-5 mb-md-2 mb-xl-4">
        <div className="row pb-lg-2">
          <div className="col-md-5 mb-4 mb-md-0">
            <div className="card justify-content-center bg-dark h-100 p-4 p-lg-5">
              <div className="p-2">
                <h3 className="display-4 text-primary mb-1">200+</h3>
                <h2 className="text-light pb-5 mb-2">Clients Already Served</h2>
                <Link to="#" className="d-inline-flex align-items-center rounded-3 text-decoration-none py-3 px-4" style={{backgroundColor: '#3e4265'}}>
                  <div className="pe-3">
                    <div className="fs-xs text-light text-uppercase opaciy-70 mb-2">Reviewed on</div>
                    <img src="/assets/img/about/clutch-logo.svg" width={97} alt="Clutch" />
                  </div>
                  <div className="ps-1">
                    <div className="text-nowrap mb-2">
                      <i className="bx bxs-star text-warning" />
                      <i className="bx bxs-star text-warning" />
                      <i className="bx bxs-star text-warning" />
                      <i className="bx bxs-star text-warning" />
                      <i className="bx bxs-star text-warning" />
                    </div>
                    <div className="text-light opacity-70">49 reviews</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card border-0 shadow-sm p-4 p-xxl-5">
              {/* Slider prev/next buttons + Quotation mark */}
              <div className="d-flex justify-content-between pb-4 mb-2">
                <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                  <i className="bx bxs-quote-left" />
                </span>
                <div className="d-flex">
                  <button type="button" id="testimonial-prev" className="btn btn-prev btn-icon btn-sm me-2" aria-label="Previous">
                    <i className="bx bx-chevron-left" />
                  </button>
                  <button type="button" id="testimonial-next" className="btn btn-next btn-icon btn-sm ms-2" aria-label="Next">
                    <i className="bx bx-chevron-right" />
                  </button>
                </div>
              </div>   
              {/* Slider */}
              <div className="swiper mx-0 mb-md-n2 mb-xxl-n3" data-swiper-options="{
                &quot;spaceBetween&quot;: 24,
                &quot;loop&quot;: true,
                &quot;pagination&quot;: {
                  &quot;el&quot;: &quot;.swiper-pagination&quot;,
                  &quot;clickable&quot;: true
                },
                &quot;navigation&quot;: {
                  &quot;prevEl&quot;: &quot;#testimonial-prev&quot;,
                  &quot;nextEl&quot;: &quot;#testimonial-next&quot;
                }
              }">
                <div className="swiper-wrapper">
                  {/* Item */}
                  <div className="swiper-slide h-auto" data-swiper-tab="#author-1">
                    <figure className="card h-100 position-relative border-0 bg-transparent">
                      <blockquote className="card-body p-0 mb-0">
                        <p className="fs-lg mb-0">Dolor, a eget elementum, integer nulla volutpat, nunc, sit. Quam iaculis varius mauris magna sem. Egestas sed sed suscipit dolor faucibus dui imperdiet at eget. Tincidunt imperdiet quis hendrerit aliquam feugiat neque cras sed. Dictum quam integer volutpat tellus, faucibus platea. Pulvinar turpis proin faucibus at mauris. Sagittis gravida vitae porta enim.</p>
                      </blockquote>
                      <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                        <img src="/assets/img/avatar/05.jpg" width={48} className="rounded-circle" alt="Ralph Edwards" />
                        <div className="ps-3">
                          <h5 className="fw-semibold lh-base mb-0">Ralph Edwards</h5>
                          <span className="fs-sm text-muted">Head of Marketing at Lorem Company</span>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  {/* Item */}
                  <div className="swiper-slide h-auto" data-swiper-tab="#author-2">
                    <figure className="card h-100 position-relative border-0 bg-transparent">
                      <blockquote className="card-body p-0 mb-0">
                        <p className="fs-lg mb-0">Mi semper risus ultricies orci pulvinar in at enim orci. Quis facilisis nunc pellentesque in ullamcorper sit. Lorem blandit arcu sapien, senectus libero, amet dapibus cursus quam. Eget pellentesque eu purus volutpat adipiscing malesuada. Purus nisi, tortor vel lacus. Donec diam molestie ultrices vitae eget pulvinar fames. Velit lacus mi purus velit justo, amet.</p>
                      </blockquote>
                      <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                        <img src="/assets/img/avatar/06.jpg" width={48} className="rounded-circle" alt="Annette Black" />
                        <div className="ps-3">
                          <h5 className="fw-semibold lh-base mb-0">Annette Black</h5>
                          <span className="fs-sm text-muted">Strategic Advisor at Ipsum Ltd.</span>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  {/* Item */}
                  <div className="swiper-slide h-auto" data-swiper-tab="#author-3">
                    <figure className="card h-100 position-relative border-0 bg-transparent">
                      <blockquote className="card-body p-0 mb-0">
                        <p className="fs-lg mb-0">Ac at sed sit senectus massa. Massa ante amet ultrices magna porta tempor. Aliquet diam in et magna ultricies mi at. Lectus enim, vel enim egestas nam pellentesque et leo. Elit mi faucibus laoreet aliquam pellentesque sed aliquet integer massa. Orci leo tortor ornare id mattis auctor aliquam volutpat aliquet. Odio lectus viverra eu blandit nunc malesuada vitae eleifend pulvinar.</p>
                      </blockquote>
                      <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                        <img src="/assets/img/avatar/01.jpg" width={48} className="rounded-circle" alt="Darrell Steward" />
                        <div className="ps-3">
                          <h5 className="fw-semibold lh-base mb-0">Darrell Steward</h5>
                          <span className="fs-sm text-muted">Project Manager at Company Ltd.</span>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                {/* Pagination (bullets) */}
                <div className="swiper-pagination position-relative pt-3 mt-4" />
              </div>        
            </div>
          </div>
        </div>
      </section>

      {/* Brands (Carousel) */}
      <section className="container pb-5 mb-lg-2 mb-xl-4">
        <div className="swiper mx-n2" data-swiper-options="{
          &quot;slidesPerView&quot;: 2,
          &quot;pagination&quot;: {
            &quot;el&quot;: &quot;.swiper-pagination&quot;,
            &quot;clickable&quot;: true
          },
          &quot;breakpoints&quot;: {
            &quot;500&quot;: {
              &quot;slidesPerView&quot;: 3,
              &quot;spaceBetween&quot;: 8
            },
            &quot;650&quot;: {
              &quot;slidesPerView&quot;: 4,
              &quot;spaceBetween&quot;: 8
            },
            &quot;900&quot;: {
              &quot;slidesPerView&quot;: 5,
              &quot;spaceBetween&quot;: 8
            },
            &quot;1100&quot;: {
              &quot;slidesPerView&quot;: 6,
              &quot;spaceBetween&quot;: 8
            }
          }
        }">
          <div className="swiper-wrapper">
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/01.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/02.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/03.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/04.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/05.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
            {/* Item */}
            <div className="swiper-slide py-3">
              <Link to="#" className="card card-body card-hover px-2 mx-2">
                <img src="/assets/img/brands/06.svg" className="d-block mx-auto my-2" width={154} alt="Brand" />
              </Link>
            </div>
          </div>
          {/* Pagination (bullets) */}
          <div className="swiper-pagination position-relative pt-2 mt-4" />
        </div>
      </section>

      {/* News slider */}
      <section className="bg-secondary border-bottom border-light py-5">
        <div className="container py-md-3 py-lg-5">
          <h2 className="h1 text-center pb-2">News &amp; Insights</h2>
          <div className="position-relative mx-md-2 px-md-5">
            {/* Slider controls (Prev / next) */}
            <button type="button" id="news-prev" className="btn btn-prev btn-icon btn-sm position-absolute top-50 start-0 translate-middle-y mt-n4 d-none d-md-inline-flex" aria-label="Previous">
              <i className="bx bx-chevron-left" />
            </button>
            <button type="button" id="news-next" className="btn btn-next btn-icon btn-sm position-absolute top-50 end-0 translate-middle-y mt-n4 d-none d-md-inline-flex" aria-label="Next">
              <i className="bx bx-chevron-right" />
            </button>
            {/* Swiper slider */}
            <div className="swiper swiper-nav-onhover mx-n2" data-swiper-options="{
              &quot;slidesPerView&quot;: 1,
              &quot;loop&quot;: true,
              &quot;spaceBetween&quot;: 8,
              &quot;pagination&quot;: {
                &quot;el&quot;: &quot;.swiper-pagination&quot;,
                &quot;clickable&quot;: true
              },
              &quot;navigation&quot;: {
                &quot;prevEl&quot;: &quot;#news-prev&quot;,
                &quot;nextEl&quot;: &quot;#news-next&quot;
              },
              &quot;breakpoints&quot;: {
                &quot;0&quot;: {
                  &quot;slidesPerView&quot;: 1
                },
                &quot;560&quot;: {
                  &quot;slidesPerView&quot;: 2
                },
                &quot;992&quot;: {
                  &quot;slidesPerView&quot;: 3
                }
              }
            }">
              <div className="swiper-wrapper">
                {/* Item */}
                <div className="swiper-slide h-auto py-3">
                  <article className="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Link to="#" className="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">Tech</Link>
                        <span className="fs-sm text-muted">1 day ago</span>
                      </div>
                      <h3 className="h4">
                        <Link to="/blog/asnec/evenementiel/blog-single" className="stretched-link">How the Millennial Lifestyle Changes as Service Prices Rise</Link>
                      </h3>
                      <p className="mb-0">Sit facilisis dolor arcu, fermentum vestibulum arcu elementum imperdiet. Mauris duis eleifend faucibus amet sagittis.</p>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-lg me-1" />
                        <span className="fs-sm">2</span>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-comment fs-lg me-1" />
                        <span className="fs-sm">0</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bx bx-share-alt fs-lg me-1" />
                        <span className="fs-sm">3</span>
                      </div>
                    </div>
                  </article>
                </div>
                {/* Item */}
                <div className="swiper-slide h-auto py-3">
                  <article className="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Link to="#" className="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">Strategy</Link>
                        <span className="fs-sm text-muted">12 hours ago</span>
                      </div>
                      <h3 className="h4">
                        <Link to="/blog/asnec/evenementiel/blog-single" className="stretched-link">Mobile App Generates Data for the Energy Management</Link>
                      </h3>
                      <p className="mb-0">Sociis sit risus id. Sit facilisis dolor arcu, fermentum vestibulum arcu ele. Pulvinar at maecenas maecenas pharetra, tincidunt sollicitudin in in. </p>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-lg me-1" />
                        <span className="fs-sm">8</span>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-comment fs-lg me-1" />
                        <span className="fs-sm">4</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bx bx-share-alt fs-lg me-1" />
                        <span className="fs-sm">2</span>
                      </div>
                    </div>
                  </article>
                </div>
                {/* Item */}
                <div className="swiper-slide h-auto py-3">
                  <article className="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Link to="#" className="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">Software</Link>
                        <span className="fs-sm text-muted">May 24, 2023</span>
                      </div>
                      <h3 className="h4">
                        <Link to="/blog/asnec/developpement-web/blog-single" className="stretched-link">What the Software Stock Slump Means for the Market</Link>
                      </h3>
                      <p className="mb-0">Id mollis consectetur congue egestas egestas suspendisse blandit justo. Tellus augue commodo id quis tempus etiam pulvinar at maecenas.</p>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-lg me-1" />
                        <span className="fs-sm">6</span>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-comment fs-lg me-1" />
                        <span className="fs-sm">1</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bx bx-share-alt fs-lg me-1" />
                        <span className="fs-sm">5</span>
                      </div>
                    </div>
                  </article>
                </div>
                {/* Item */}
                <div className="swiper-slide h-auto py-3">
                  <article className="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Link to="#" className="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">Startups</Link>
                        <span className="fs-sm text-muted">Sep 3, 2023</span>
                      </div>
                      <h3 className="h4">
                        <Link to="/blog/asnec/developpement-web/blog-single" className="stretched-link">5 Bad Landing Page Examples &amp; How We Would Fix Them</Link>
                      </h3>
                      <p className="mb-0">Totam, soluta placeat hic adipisci reprehenderit iusto est nulla dolorum doloremque inventore suscipit consequuntur distinctio id.</p>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-lg me-1" />
                        <span className="fs-sm">8</span>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-comment fs-lg me-1" />
                        <span className="fs-sm">7</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bx bx-share-alt fs-lg me-1" />
                        <span className="fs-sm">3</span>
                      </div>
                    </div>
                  </article>
                </div>
                {/* Item */}
                <div className="swiper-slide h-auto py-3">
                  <article className="card p-md-3 p-2 border-0 shadow-sm card-hover-primary h-100 mx-2">
                    <div className="card-body pb-0">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <Link to="#" className="badge fs-sm text-nav bg-secondary text-decoration-none position-relative zindex-2">Technology</Link>
                        <span className="fs-sm text-muted">Aug 19, 2023</span>
                      </div>
                      <h3 className="h4">
                        <Link to="/blog/asnec/developpement-web/blog-single" className="stretched-link">Why UX Design Matters and How it Affects Ranking</Link>
                      </h3>
                      <p className="mb-0">Quaerat quos assumenda numquam dolorem, repellendus est, itaque sint et odio perferendis laudantium laboriosam.</p>
                    </div>
                    <div className="card-footer d-flex align-items-center py-4 text-muted border-top-0">
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-lg me-1" />
                        <span className="fs-sm">5</span>
                      </div>
                      <div className="d-flex align-items-center me-3">
                        <i className="bx bx-comment fs-lg me-1" />
                        <span className="fs-sm">3</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="bx bx-share-alt fs-lg me-1" />
                        <span className="fs-sm">9</span>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              {/* Pagination (bullets) */}
              <div className="swiper-pagination position-relative pt-2 pt-sm-3 mt-4" />
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="position-relative bg-dark py-5">
        <span className="position-absolute top-0 start-0 w-100 h-100" style={{backgroundColor: 'rgba(255, 255, 255, .05)'}} />
        <div className="container position-relative zindex-5 text-center my-xl-3 py-1 py-md-4 py-lg-5">
          <p className="lead text-light opacity-70 mb-3">Prêt à commencer ?</p>
          <h2 className="h1 text-light pb-3 pb-lg-0 mb-lg-5">Commencer un projet avec nous</h2>
          <Link to="#" className="btn btn-primary shadow-primary btn-lg">Demander un dévis</Link>
        </div>
      </section>

    </>    
  );
}

export default Home;