import { useEffect } from "react";

import Header from "../../components/Header";


function Portfolio(props) {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/vendor/jarallax/dist/jarallax.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/vendor/rellax/rellax.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/theme.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        
        <>
            <Header navBar="default" />
            
            {/* Breadcrumb */}
            <nav className="container py-4 my-lg-3" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                    <a href="index-2.html"><i className="bx bx-home-alt fs-lg me-1" />Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Portfolio List View</li>
                </ol>
            </nav>

            {/* Page title + filters */}
            <section className="container d-sm-flex align-items-center justify-content-between pb-4 mb-2 mb-lg-3">
                <h1 className="mb-sm-0 me-sm-3">Portfolio List</h1>
                <select className="form-select" style={{width: 240}}>
                    <option value="All categories">All categories</option>
                    <option value="Web Design">Web Design</option>
                    <option value="Graphic Design">Graphic Design</option>
                    <option value="Branding">Branding</option>
                    <option value="UI / UX">UI / UX</option>
                    <option value="Motion Design">Motion Design</option>
                </select>
            </section>

            {/* Portfolio list */}
            <section className="container pb-2 pb-lg-3">
            {/* Item */}
            <div className="row pb-5 mb-md-4 mb-lg-5">
                <div className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0" data-rellax-percentage="0.5" data-rellax-speed="0.8" data-disable-parallax-down="md">
                <a href="portfolio-single-project.html">
                    <img src="assets/img/portfolio/list/01.jpg" className="rounded-3" width={600} alt="Image" />
                </a>
                </div>
                <div className="rellax col-md-6" data-rellax-percentage="0.5" data-rellax-speed="-0.6" data-disable-parallax-down="md">
                <div className="ps-md-4 ms-md-2">
                    <div className="fs-sm text-muted mb-1">Nov 18, 2023</div>
                    <h2 className="h3">SaaS Project Management Tool</h2>
                    <a href="#" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">Graphic Design</a>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.</p>
                    <a href="portfolio-single-project.html" className="btn btn-outline-primary">View more</a>
                </div>
                </div>
            </div>
            {/* Item */}
            <div className="row pb-5 mb-md-4 mb-lg-5">
                <div className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0" data-rellax-percentage="0.5" data-rellax-speed="0.8" data-disable-parallax-down="md">
                <a href="portfolio-single-project.html" className="float-md-end">
                    <img src="assets/img/portfolio/list/02.jpg" className="rounded-3" width={600} alt="Image" />
                </a>
                </div>
                <div className="rellax col-md-6 order-md-1 pt-md-4 pt-lg-5" data-rellax-percentage="0.5" data-rellax-speed="-0.6" data-disable-parallax-down="md">
                <div className="pe-md-4 me-md-2">
                    <div className="fs-sm text-muted mb-1">Nov 9, 2023</div>
                    <h2 className="h3">Mobile Banking App</h2>
                    <a href="#" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">UI / UX</a>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.</p>
                    <a href="portfolio-single-project.html" className="btn btn-outline-primary">View more</a>
                </div>
                </div>
            </div>
            {/* Item */}
            <div className="row pb-5 mb-md-4 mb-lg-5">
                <div className="rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0" data-rellax-percentage="0.5" data-rellax-speed="0.8" data-disable-parallax-down="md">
                <a href="portfolio-single-project.html">
                    <img src="assets/img/portfolio/list/03.jpg" className="rounded-3" width={600} alt="Image" />
                </a>
                </div>
                <div className="rellax col-md-6 pt-md-4 pt-lg-5" data-rellax-percentage="0.5" data-rellax-speed="-0.6" data-disable-parallax-down="md">
                <div className="ps-md-4 ms-md-2">
                    <div className="fs-sm text-muted mb-1">Oct 25, 2023</div>
                    <h2 className="h3">Tool for Task Management </h2>
                    <a href="#" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">Motion Design</a>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">Tortor suspendisse enim convallis mauris, tincidunt ornare non tempor, turpis. Ultricies elit vitae imperdiet luctus lorem tellus, vel. Non sodales facilisis amet vulputate massa ultricies volutpat consectetur velit.</p>
                    <a href="portfolio-single-project.html" className="btn btn-outline-primary">View more</a>
                </div>
                </div>
            </div>
            {/* Item */}
            <div className="row pb-5 mb-md-4 mb-lg-5">
                <div className="rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0" data-rellax-percentage="0.5" data-rellax-speed="0.8" data-disable-parallax-down="md">
                <a href="portfolio-single-project.html" className="float-md-end">
                    <img src="assets/img/portfolio/list/04.jpg" className="rounded-3" width={600} alt="Image" />
                </a>
                </div>
                <div className="rellax col-md-6 order-md-1 pt-md-4 pt-lg-5" data-rellax-percentage="0.5" data-rellax-speed="-0.6" data-disable-parallax-down="md">
                <div className="pe-md-4 me-md-2">
                    <div className="fs-sm text-muted mb-1">Aug 23, 2023</div>
                    <h2 className="h3">Hospital and Healthcare Website</h2>
                    <a href="#" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">Web Design</a>
                    <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">Adipiscing amet placerat orci, non iaculis. Velit nec nec risus, ultrices mus integer nibh bibendum. In sagittis, habitant viverra nibh massa luctus. Lobortis tristique lorem in nisl, aliquam eu pretium hendrerit. Mauris, varius interdum dui elementum neque mi. Tortor amet ornare felis erat.  Arcu enim nunc, porttitor libero massa.</p>
                    <a href="portfolio-single-project.html" className="btn btn-outline-primary">View more</a>
                </div>
                </div>
            </div>
            </section>

            {/* Contact CTA */}
            <section className="container mb-1 mb-md-3 mb-lg-4">
            <div className="bg-secondary rounded-3 overflow-hidden">
                <div className="row align-items-center">
                <div className="col-xl-4 col-md-5 offset-lg-1">
                    <div className="pt-5 pb-3 pb-md-5 px-4 px-lg-0 text-center text-md-start">
                    <p className="lead mb-3">Ready to get started?</p>
                    <h2 className="h1 pb-3 pb-sm-4">Take Your <span className="text-primary">Business</span> to&nbsp;the Next Level</h2>
                    <a href="#" className="btn btn-primary btn-lg">Work with us</a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-7 offset-xl-1">
                    <div className="position-relative d-flex flex-column align-items-center justify-content-center h-100">
                    <svg className="d-none d-md-block position-absolute top-50 start-0 translate-middle-y" width={868} height={868} style={{minWidth: 868}} viewBox="0 0 868 868" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity="0.15" cx={434} cy={434} r={434} fill="#6366F1" /></svg>
                    <img src="assets/img/about/cta.png" className="position-relative zindex-3 mb-2 my-lg-4" width={382} alt="Illustration" />
                    </div>
                </div>
                </div>
            </div>
            </section>

        </>

    );
}

export default Portfolio;