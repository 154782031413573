import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from "../../components/Header";


function Error404(props) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/vendor/parallax-js/dist/parallax.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/theme.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const footer = document.querySelector("footer");

    if (footer) {
      footer.style.display = 'none';
    }

    return () => {
      if (footer) {
        footer.style.display = '';
      }
    };
  }, []);



  return (

    <>

      {/* BG light version */}
      <div className="d-dark-mode-none position-absolute top-0 start-0 w-100 h-100 bg-repeat-0 bg-position-center bg-size-cover" style={{backgroundImage: 'url(/assets/img/404/light/bg.jpg)'}} />
      
      {/* BG dark version */}
      <div className="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100 bg-repeat-0 bg-position-center bg-size-cover" style={{backgroundImage: 'url(/assets/img/404/dark/bg.jpg)'}} />

      <Header navBar="Error404" />

      {/* Page content */}
      <section className="container d-flex flex-column h-100 align-items-center position-relative zindex-5 pt-5">
        <div className="text-center pt-5 pb-3 mt-auto">
          {/* Parallax gfx (Light version) */}
          <div className="parallax mx-auto d-dark-mode-none" style={{maxWidth: 574}}>
            <div className="parallax-layer" data-depth="-0.15">
              <img src="/assets/img/404/light/layer01.png" alt="Layer" />
            </div>
            <div className="parallax-layer" data-depth="0.12">
              <img src="/assets/img/404/light/layer02.png" alt="Layer" />
            </div>
            <div className="parallax-layer zindex-5" data-depth="-0.12">
              <img src="/assets/img/404/light/layer03.png" alt="Layer" />
            </div>
          </div>
          {/* Parallax gfx (Dark version) */}
          <div className="parallax mx-auto d-none d-dark-mode-block" style={{maxWidth: 574}}>
            <div className="parallax-layer" data-depth="-0.15">
              <img src="/assets/img/404/dark/layer01.png" alt="Layer" />
            </div>
            <div className="parallax-layer" data-depth="0.12">
              <img src="/assets/img/404/dark/layer02.png" alt="Layer" />
            </div>
            <div className="parallax-layer zindex-5" data-depth="-0.12">
              <img src="/assets/img/404/dark/layer03.png" alt="Layer" />
            </div>
          </div>
          <h1 className="visually-hidden">404</h1>
          <h2 className="display-5">Ooops!</h2>
          <p className="fs-xl pb-3 pb-md-0 mb-md-5">The page you are looking for is not available.</p>
          <Link to="/" className="btn btn-lg btn-primary shadow-primary w-sm-auto w-100">
            <i className="bx bx-home-alt me-2 ms-n1 lead" />
            Go to Home
          </Link>
        </div>
        <div className="text-center py-lg-5 py-4 mt-auto">
          <p className="nav d-block fs-sm pt-3 pt-lg-0 mb-0">
            <span className="opacity-75">© All rights reserved. Made by </span> 
            <Link className="nav-link d-inline-block p-0" href="https://createx.studio/" target="_blank" rel="noopener"> ASNEC-IT</Link>
          </p>
        </div>  
      </section>


    </>

  );
}

export default Error404;