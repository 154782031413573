import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";


function NavbarNav(props) {

    const [activeItem, setActiveItem] = useState('Home');
    const location = useLocation(); 

    useEffect(() => {
        const path = location.pathname; 
        setActiveItem(path); 
    }, [location]);

    return (
        
        <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
                <h5 className="offcanvas-title">Menu</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>

            <div className="offcanvas-body">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                    <li className="nav-item">
                        <Link to="/" className={`nav-link ${activeItem === '/' ? 'active' : ''}`} >Acceuil</Link>
                    </li>

                    <li className="nav-item dropdown">
                        <Link to="/services-all" className={`nav-link dropdown-toggle ${activeItem === '/Services' ? 'active' : ''}`} data-bs-toggle="dropdown">Services Et Prestations</Link>
                        <ul className="dropdown-menu">
                            <li><Link to="/services/asnec/solution-pme" className="dropdown-item">Solution PME</Link></li>
                            <li><Link to="/services/asnec/evenementiel" className="dropdown-item">Evenementiel</Link></li>

                            <li className="dropdown">
                                <Link to="#" className="dropdown-item dropdown-toggle">Communication</Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/services/asnec/Communication/studio-graphique" className="dropdown-item">Studio Graphique</Link></li>
                                    <li><Link to="/services/asnec/Communication/marketing-digital-publicité" className="dropdown-item">Marketing Digital Et Publicité</Link></li>
                                </ul>
                            </li>

                            <li className="dropdown">
                                <Link to="#" className="dropdown-item dropdown-toggle">Développement Web</Link>
                                <ul className="dropdown-menu">
                                    <li><Link to="/services/asnec/developpement-web/creation-sites-internet" className="dropdown-item">Création Des Sites Internet</Link></li>
                                    <li><Link to="/services/asnec/developpement-web/audit-maintenance-web" className="dropdown-item">Audit Et Maintenance De Site Web</Link></li>
                                    <li><Link to="/services/asnec/developpement-web/administration-site-web" className="dropdown-item">Administration De Site Web</Link></li>
                                    <li><Link to="/services/asnec/developpement-web/hebergement-sites-web" className="dropdown-item">Hébergement Des Sites Web</Link></li>
                                </ul>
                            </li>
                            
                            <li><Link to="/services/asnec/developpement-logiciel" className="dropdown-item">Développement Logiciel</Link></li>
                            <li><Link to="/services/asnec/developpement-mobil" className="dropdown-item">Développement Mobile</Link></li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link to="/about" className={`nav-link ${activeItem === '/about' ? 'active' : ''}`} >À propos</Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/training" className={`nav-link ${activeItem === '/training' ? 'active' : ''}`} >Training</Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/portfolio" className={`nav-link ${activeItem === '/portfolio' ? 'active' : ''}`} >Portfolio</Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/blog" className={`nav-link ${activeItem === '/blog' ? 'active' : ''}`} >Blog</Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/contact" className={`nav-link ${activeItem === '/contact' ? 'active' : ''}`} >Contact</Link>
                    </li>                            
                </ul>
            </div>
        </div>

    );
}

export default NavbarNav;