import React from 'react';
import { Link } from 'react-router-dom';

function Price(props) {
    return (
      
      Price

    );
}

export default Price;