import React from 'react';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';

import Home from './pages/asnec-agency/Home';
import About from './pages/about/About';

import ServicesAll from './pages/services/ServicesAll';
import DevWeb from './pages/services/DevWeb';
import DevMobil from './pages/services/DevMobil';
import Evens from './pages/services/Evens';

import ServicesSingle from './pages/services/ServicesSingle';

import DevLogiciel from './pages/services/DevLogiciel';
import PME from './pages/services/PME';
import StudioGraph from './pages/services/StudioGraph';
import MarkDigital from './pages/services/MarkDigital';
import AuditWeb from './pages/services/AuditWeb';
import AdminWeb from './pages/services/AdminWeb';
import HebergeWeb from './pages/services/HebergeWeb';

import Training from './pages/training/Training';
import Portfolio from './pages/portfolio/Portfolio';
import Contact from './pages/contact/Contact';

import Blog from './pages/blog/Blog';
import BlogSingle from './pages/blog/BlogSingle';

import Price from './pages/price/Price';
import Faq from './pages/faq/Faq';
import Error404 from './pages/error404/Error404';


const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "about",
    element: <About />
  },
  {
    path: "training",
    element: <Training />
  },
  {
    path: "portfolio",
    element: <Portfolio />
  },
  {
    path: "blog",
    element: <Blog />
  },
  {
    path: "contact",
    element: <Contact />
  },
  {
    path: "services-all",
    element: <ServicesAll />
  },
  {
    path: "services/asnec/price",
    element: <Price />
  },
  {
    path: "services/asnec/solution-pme",
    element: <PME />
  },
  {
    path: "services/asnec/Communication/studio-graphique",
    element: <StudioGraph />
  },
  {
    path: "services/asnec/Communication/marketing-digital-publicite",
    element: <MarkDigital />
  },
  {
    path: "/services/asnec/developpement-web/audit-maintenance-web",
    element: <AuditWeb />
  },
  {
    path: "/services/asnec/developpement-web/administration-site-web",
    element: <AdminWeb />
  },
  {
    path: "/services/asnec/developpement-web/hebergement-sites-web",
    element: <HebergeWeb />
  },
  {
    path: "services/asnec/developpement-web/creation-sites-internet",
    element: <DevWeb />
  },
  {
    path: "services/asnec/developpement-mobil",
    element: <DevMobil />
  },
  {
    path: "services/asnec/developpement-logiciel",
    element: <DevLogiciel />
  },
  {
    path: "services/asnec/evenementiel",
    element: <Evens />
  },
  {
    path: "services/asnec/developpement-web/services-single",
    element: <ServicesSingle />
  },
  {
    path: "services/asnec/developpement-mobil/services-single",
    element: <ServicesSingle />
  },
  {
    path: "services/asnec/evenementiel/services-single",
    element: <ServicesSingle />
  },
  {
    path: "services/asnec/faq",
    element: <Faq />
  },
  {
    path: "/blog/asnec/evenementiel/blog-single",
    element: <BlogSingle />
  },
  {
    path: "/blog/asnec/developpement-web/blog-single",
    element: <BlogSingle />
  },
  {
    path: "/blog/asnec/developpement-mobil/blog-single",
    element: <BlogSingle />
  },
  {
    path: "*",
    element: <Error404 />
  },
])


function App() {
  return (
    <RouterProvider router={routes} />
  );
}

export default App;
