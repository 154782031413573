import { useEffect } from "react";


import Header from "../../components/Header";

function Training(props) {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/theme.min.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <Header navBar="default" />
            
            {/* Page content */}
            <section className="container">
            {/* Breadcrumb */}
            <nav className="pt-4 mt-lg-3" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                    <a href="landing-online-courses.html"><i className="bx bx-home-alt fs-lg me-1" />Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Courses</li>
                </ol>
            </nav>
            {/* Page title + Filters */}
            <div className="d-lg-flex align-items-center justify-content-between py-4 mt-lg-2">
                <h1 className="me-3">Courses</h1>
                <div className="d-md-flex mb-3">
                <select className="form-select me-md-4 mb-2 mb-md-0" style={{minWidth: 240}}>
                    <option value="All">All categories</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Mobile Development">Mobile Development</option>
                    <option value="Programming">Programming</option>
                    <option value="Game Development">Game Development</option>
                    <option value="Software Testing">Software Testing</option>
                    <option value="Software Engineering">Software Engineering</option>
                    <option value="Network & Security">Network &amp; Security</option>
                </select>
                <div className="position-relative" style={{minWidth: 300}}>
                    <input type="text" className="form-control pe-5" placeholder="Search courses" />
                    <i className="bx bx-search text-nav fs-lg position-absolute top-50 end-0 translate-middle-y me-3" />
                </div>
                </div>
            </div>
            {/* Courses grid */}
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <span className="badge bg-success position-absolute top-0 start-0 zindex-2 mt-3 ms-3">Best Seller</span>
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/01.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">Fullstack Web Developer Course from Scratch</a>
                    </h3>
                    <p className="fs-sm mb-2">By Albert Flores</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$12.50</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        220 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        94% (4.2K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/02.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">HTML, CSS, JavaScript Web Developer</a>
                    </h3>
                    <p className="fs-sm mb-2">By Jenny Wilson &amp; Marvin McKinney</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$15.99</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        160 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        92% (3.1K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <span className="badge bg-danger position-absolute top-0 start-0 zindex-2 mt-3 ms-3">Sale!</span>
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/03.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">HTML, CSS, JavaScript Web Developer</a>
                    </h3>
                    <p className="fs-sm mb-2">By Robert Fox</p>
                    <p className="text-muted mb-0"><span className="fs-lg fw-semibold text-danger me-2">$9.99</span><del>$44.99</del></p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        210 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        98% (2.7K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/04.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">iOS &amp; Swift - The Complete iOS App Development Course</a>
                    </h3>
                    <p className="fs-sm mb-2">By Marvin McKinney</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$15.99</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        170 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        95% (3.3K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <span className="badge bg-success position-absolute top-0 start-0 zindex-2 mt-3 ms-3">Best Seller</span>
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/05.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">Data Science &amp; Machine Learning with Python</a>
                    </h3>
                    <p className="fs-sm mb-2">By Esther Howard</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$19.75</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        150 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        96% (3.8K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/06.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">Creative CSS Drawing Course: Make Art With CSS</a>
                    </h3>
                    <p className="fs-sm mb-2">By Robert Fox</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$17.99</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        110 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        92% (5.3K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/07.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">Blender Character Creator v2.0 for Video Games Design</a>
                    </h3>
                    <p className="fs-sm mb-2">By Ralph Edwards</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$25.00</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        160 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        93% (4.5K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <span className="badge bg-danger position-absolute top-0 start-0 zindex-2 mt-3 ms-3">Sale!</span>
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/08.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">The Ultimate Guide to 2D Mobile Game Development with Unity</a>
                    </h3>
                    <p className="fs-sm mb-2">By Albert Flores</p>
                    <p className="text-muted mb-0"><span className="fs-lg fw-semibold text-danger me-2">$12.99</span><del>$34.99</del></p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        230 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        97% (4.1K)
                    </div>
                    </div>
                </article>
                </div>
                {/* Item */}
                <div className="col pb-1 pb-lg-3 mb-4 d-sm-none d-lg-block">
                <article className="card h-100 border-0 shadow-sm">
                    <div className="position-relative">
                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link" />
                    <a href="#" className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" title="Save to Favorites" aria-label="Save to Favorites">
                        <i className="bx bx-bookmark" />
                    </a>
                    <img src="assets/img/portfolio/courses/09.jpg" className="card-img-top" alt="Image" />
                    </div>
                    <div className="card-body pb-3">
                    <h3 className="h5 mb-2">
                        <a href="portfolio-single-course.html">Learn JMETER from Scratch on Live Apps-Performance Testing</a>
                    </h3>
                    <p className="fs-sm mb-2">By Jenny Wilson</p>
                    <p className="fs-lg fw-semibold text-primary mb-0">$14.50</p>
                    </div>
                    <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                    <div className="d-flex align-items-center me-4">
                        <i className="bx bx-time fs-xl me-1" />
                        120 hours
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bx bx-like fs-xl me-1" />
                        92% (3.8K)
                    </div>
                    </div>
                </article>
                </div>
            </div>
            {/* Pagination: Basic example */}
            <nav className="pb-5" aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                <li className="page-item">
                    <a href="#" className="page-link">
                    <i className="bx bx-chevron-left ms-n1 me-1" />
                    Prev
                    </a>
                </li>
                <li className="page-item disabled d-sm-none">
                    <span className="page-link text-body">1 / 4</span>
                </li>
                <li className="page-item active d-none d-sm-block" aria-current="page">
                    <span className="page-link">
                    1
                    <span className="visually-hidden">(current)</span>
                    </span>
                </li>
                <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">2</a>
                </li>
                <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">3</a>
                </li>
                <li className="page-item d-none d-sm-block">
                    <a href="#" className="page-link">4</a>
                </li>
                <li className="page-item">
                    <a href="#" className="page-link">
                    Next
                    <i className="bx bx-chevron-right me-n1 ms-1" />
                    </a>
                </li>
                </ul>
            </nav>
            {/* Sign up CTA */}
            <div className="bg-secondary rounded-3 my-2 my-md-4 my-lg-5 py-5 px-3 px-md-0">
                <div className="row align-items-center">
                <div className="col-md-5 offset-lg-1 text-center text-md-start ps-md-5 ps-lg-0 ps-xl-5 pb-2 pb-md-0 mb-4 mb-md-0">
                    <p className="lead mb-3">Ready to get started?</p>
                    <h2 className="h1 pb-2 pb-md-4">Take Your <span className="text-primary">Skills</span> to&nbsp;the Next Level</h2>
                    <a href="#" className="btn btn-primary btn-lg">Sign up now!</a>
                </div>
                <div className="col-lg-6 col-md-7">
                    <div className="d-table mx-auto">
                    <img src="assets/img/landing/online-courses/steps/04-dark.svg" className="d-dark-mode-none" width={380} alt="Illustration" />
                    <img src="assets/img/landing/online-courses/steps/04-light.svg" className="d-none d-dark-mode-block" width={380} alt="Illustration" />
                    </div>
                </div>
                </div>
            </div>
            </section>

        </>
    );
}

export default Training;