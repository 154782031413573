import React from 'react';

import Header from "../../components/Header";

function StudioGraph(props) {
    return (
        <>
            <Header navBar="default" />


            
            
        </>
    );
}

export default StudioGraph;