import React from 'react';

function ServicesAll(props) {
    return (
        <div>
            
        </div>
    );
}

export default ServicesAll;