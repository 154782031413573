import React from 'react';

import Header from "../../components/Header";

function HebergeWeb(props) {
    return (
        <>
            <Header navBar="AboutAndContact" />
            
        </>
    );
}

export default HebergeWeb;