import { useEffect } from "react";

import Header from "../../components/Header";

function DevWeb(props) {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/vendor/swiper/swiper-bundle.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/theme.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  return (
    <>
      <Header navBar="default" />

      <div className="bg-secondary pt-5" style={{paddingBottom: 228}}>
        {/* Breadcrumb */}
        <nav className="container pt-5 pb-4 mb-lg-2" aria-label="breadcrumb">
          <ol className="breadcrumb pt-lg-3 mb-0">
            <li className="breadcrumb-item">
              <a href="index-2.html"><i className="bx bx-home-alt fs-lg me-1" />Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Services v.1</li>
          </ol>
        </nav>
        {/* Page title + Services grid */}
        <section className="container pb-2 pb-md-4 pb-lg-5 mb-3">
          <h1 className="pb-4">Services v.1</h1>
          <div className="row row-cols-1 row-cols-md-2">
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/cms.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Custom Software Development
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Nisi, dis sed cursus eget pellentesque mattis. Odio eu proin aliquam a. Semper bibendum tellus non tellus, facilisi dignissim in quam massa. Aliquam, feugiat ut cum tellus, sit. Quis consectetur gravida ac ac lectus cursus egestas.</p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/rocket.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Software Integration
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Id eget blandit sapien cras massa lectus lorem placerat. Quam dolor commodo fermentum bibendum dictumst. Risus pretium eget at viverra eget. Sit neque adipiscing malesuada blandit justo, quam.</p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/mobile-app.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Mobile App Development
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Nunc, justo, diam orci, dictum purus convallis risus. Suscipit hendrerit at egestas id id blandit interdum est. Integer fames placerat turpis pretium quis hac leo lacus. Orci, dictum nunc mus quis semper eu bibendum enim, morbi.</p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/analytics.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Business Analytics
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Gravida eget euismod tempus diam dignissim quam. Dignissim magnis blandit faucibus convallis augue nisl, etiam. Feugiat ut molestie non arcu senectus sed. Diam pellentesque sit mattis nec amet varius nunc a sed.</p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/web-search.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Software Testing
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Quis rhoncus quam venenatis facilisi. Risus dis libero nisl condimentum quis. Tincidunt ultricies vulputate ornare nunc rhoncus in. Ultrices dolor eu natoque volutpat praesent curabitur ultricies.</p>
                </div>
              </a>
            </div>
            {/* Item */}
            <div className="col py-4 my-2 my-sm-3">
              <a href="services-single-v1.html" className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
                <div className="card-body pt-3">
                  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
                    <img src="/assets/img/services/icons/timer.svg" className="d-block m-1" width={40} alt="Icon" />
                  </div>
                  <h2 className="h4 d-inline-flex align-items-center">
                    Project Management
                    <i className="bx bx-right-arrow-circle text-primary fs-3 ms-2" />
                  </h2>
                  <p className="fs-sm text-body mb-0">Massa dis morbi sagittis, tellus sit gravida. Id ut non ut in faucibus eu, ac. Tempus feugiat enim id pellentesque a sagittis vitae, convallis. Nunc, arcu enim orci ullamcorper aenean. Scelerisque eget a nibh bibendum commodo.</p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>

      {/* Testimonials */}
      <section className="container pb-5 mb-2 mb-lg-4" style={{marginTop: '-228px'}}>
        <h2 className="h1 pb-3 pb-lg-4">What Our Clients Say</h2>
        <div className="row">
          <div className="col-md-4 d-none d-md-block">
            {/* Swiper tabs (Author images) */}
            <div className="swiper-tabs">
              {/* Author 1 image */}
              <div id="author-1" className="card bg-transparent border-0 swiper-tab active">
                <div className="card-body p-0 rounded-3 bg-size-cover bg-repeat-0 bg-position-top-center" style={{backgroundImage: 'url(/assets/img/testimonials/01.jpg)'}} />
              </div>
              {/* Author 2 image */}
              <div id="author-2" className="card bg-transparent border-0 swiper-tab">
                <div className="card-body p-0 rounded-3 bg-size-cover bg-repeat-0 bg-position-top-center" style={{backgroundImage: 'url(/assets/img/testimonials/02.jpg)'}} />
              </div>
              {/* Author 3 image */}
              <div id="author-3" className="card bg-transparent border-0 swiper-tab">
                <div className="card-body p-0 rounded-3 bg-size-cover bg-repeat-0 bg-position-top-center" style={{backgroundImage: 'url(/assets/img/testimonials/03.jpg)'}} />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="position-relative ms-xxl-5">
              <div className="d-none d-dark-mode-block bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3" />
              <div className="card border-light shadow-sm zindex-2 mt-4 pt-5 p-4 p-xxl-5">
                <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none position-absolute top-0 translate-middle-y">
                  <i className="bx bxs-quote-left" />
                </span>
                {/* Slider */}
                <div className="swiper mx-0 mb-md-n2 mb-xxl-n3" data-swiper-options="{
                  &quot;spaceBetween&quot;: 24,
                  &quot;loop&quot;: true,
                  &quot;tabs&quot;: true,
                  &quot;navigation&quot;: {
                    &quot;prevEl&quot;: &quot;#prev&quot;,
                    &quot;nextEl&quot;: &quot;#next&quot;
                  }
                }">
                  <div className="swiper-wrapper">
                    {/* Item */}
                    <div className="swiper-slide h-auto" data-swiper-tab="#author-1">
                      <figure className="card h-100 position-relative border-0 bg-transparent">
                        <blockquote className="card-body p-0 mb-0">
                          <p className="fs-lg mb-0">Ac at sed sit senectus massa. Massa ante amet ultrices magna porta tempor. Aliquet diam in et magna ultricies mi at. Lectus enim, vel enim egestas nam pellentesque et leo. Elit mi faucibus laoreet aliquam pellentesque sed aliquet integer massa. Orci leo tortor ornare id mattis auctor aliquam volutpat aliquet. Odio lectus viverra eu blandit nunc malesuada vitae eleifend pulvinar. In ac fermentum sit in orci.</p>
                        </blockquote>
                        <figcaption className="card-footer border-0 d-sm-flex w-100 pb-2">
                          <div className="d-flex align-items-center border-end-sm pe-sm-4 me-sm-2">
                            <img src="/assets/img/avatar/01.jpg" width={48} className="d-md-none rounded-circle" alt="Darrell Steward" />
                            <div className="ps-3 ps-md-0">
                              <h5 className="fw-semibold lh-base mb-0">Darrell Steward</h5>
                              <span className="fs-sm text-muted">Project Manager</span>
                            </div>
                          </div>
                          <img src="/assets/img/brands/04.svg" width={160} className="d-block mt-2 ms-5 mt-sm-0 ms-sm-0" alt="Company logo" />
                        </figcaption>
                      </figure>
                    </div>
                    {/* Item */}
                    <div className="swiper-slide h-auto" data-swiper-tab="#author-2">
                      <figure className="card h-100 position-relative border-0 bg-transparent">
                        <blockquote className="card-body p-0 mb-0">
                          <p className="fs-lg mb-0">Mi semper risus ultricies orci pulvinar in at enim orci. Quis facilisis nunc pellentesque in ullamcorper sit. Lorem blandit arcu sapien, senectus libero, amet dapibus cursus quam. Eget pellentesque eu purus volutpat adipiscing malesuada. Purus nisi, tortor vel lacus. Donec diam molestie ultrices vitae eget pulvinar fames. Velit lacus mi purus velit justo, amet. Nascetur lobortis diam, duis orci.</p>
                        </blockquote>
                        <figcaption className="card-footer border-0 d-sm-flex w-100 pb-2">
                          <div className="d-flex align-items-center border-end-sm pe-sm-4 me-sm-2">
                            <img src="/assets/img/avatar/06.jpg" width={48} className="d-md-none rounded-circle" alt="Annette Black" />
                            <div className="ps-3 ps-md-0">
                              <h5 className="fw-semibold lh-base mb-0">Annette Black</h5>
                              <span className="fs-sm text-muted">Strategic Advisor</span>
                            </div>
                          </div>
                          <img src="/assets/img/brands/02.svg" width={160} className="d-block mt-2 ms-5 mt-sm-0 ms-sm-0" alt="Company logo" />
                        </figcaption>
                      </figure>
                    </div>
                    {/* Item */}
                    <div className="swiper-slide h-auto" data-swiper-tab="#author-3">
                      <figure className="card h-100 position-relative border-0 bg-transparent">
                        <blockquote className="card-body p-0 mb-0">
                          <p className="fs-lg mb-0">Dolor, a eget elementum, integer nulla volutpat, nunc, sit. Quam iaculis varius mauris magna sem. Egestas sed sed suscipit dolor faucibus dui imperdiet at eget. Tincidunt imperdiet quis hendrerit aliquam feugiat neque cras sed. Dictum quam integer volutpat tellus, faucibus platea. Pulvinar turpis proin faucibus at mauris. Sagittis gravida vitae porta enim, nulla arcu fermentum massa.</p>
                        </blockquote>
                        <figcaption className="card-footer border-0 d-sm-flex w-100 pb-2">
                          <div className="d-flex align-items-center border-end-sm pe-sm-4 me-sm-2">
                            <img src="/assets/img/avatar/05.jpg" width={48} className="d-md-none rounded-circle" alt="Ralph Edwards" />
                            <div className="ps-3 ps-md-0">
                              <h5 className="fw-semibold lh-base mb-0">Ralph Edwards</h5>
                              <span className="fs-sm text-muted">Head of Marketing</span>
                            </div>
                          </div>
                          <img src="/assets/img/brands/01.svg" width={160} className="d-block mt-2 ms-5 mt-sm-0 ms-sm-0" alt="Company logo" />
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>        
              </div>
            </div>
          </div>
        </div>
        {/* Slider Prev / Next buttons */}
        <div className="d-flex justify-content-center justify-content-md-start pt-3 mt-2 mt-md-3">
          <button type="button" id="prev" className="btn btn-prev btn-icon btn-sm me-2" aria-label="Previous">
            <i className="bx bx-chevron-left" />
          </button>
          <button type="button" id="next" className="btn btn-next btn-icon btn-sm ms-2" aria-label="Next">
            <i className="bx bx-chevron-right" />
          </button>
        </div>
      </section>

      {/* Features */}
      <section className="container pb-5 mb-2 mb-md-4 mb-lg-5">
        <h2 className="h1 text-center pt-1 pt-xl-2 mb-4">Why Choose Us?</h2>
        <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">We personalize how we work to fit your project needs. Our approach helps augment innovation.</p>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-xl-3">
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/01.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Build a functional prototype in 24 hrs</h3>
          </div>
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/02.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Create an MVP within two weeks</h3>
          </div>
          <div className="col text-center border-end">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/03.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Kickstart software development</h3>
          </div>
          <div className="col text-center">
            <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
              <img src="/assets/img/landing/software-agency-1/features/04.svg" width={32} alt="Icon" />
            </div>
            <h3 className="h5 mb-2 mb-lg-0">Get 24/7 tech &amp; business support</h3>
          </div>
        </div>
      </section>

      {/* Contact form */}
      <section className="container">
        <div className="bg-secondary rounded-3 py-5 px-3 px-sm-4 px-lg-0">
          <div className="row align-items-center pt-1 pb-2 py-lg-4">
            <div className="col-xl-4 col-lg-5 col-md-6 offset-lg-1 mb-4 pb-3 mb-md-0 pb-md-0">
              <h2 className="h1 mb-lg-4">Get in Touch</h2>
              <p className="pb-2 pb-md-4 pb-lg-5">Have a project in mind? To request a quote contact us directly or fill out the form and let us know how we can help.</p>
              <h3 className="mb-lg-4">Contact Info</h3>
              <ul className="list-unstyled pb-3 pb-md-4 pb-lg-5 mb-2">
                <li className="mb-2">
                  <a href="tel:4065550120" className="nav-link d-flex align-items-center p-0">
                    <i className="bx bx-phone-call fs-xl text-primary me-2" />
                    (406)&nbsp;555‑0120
                  </a>
                </li>
                <li className="mb-2">
                  <a href="mailto:example@email.com" className="nav-link d-flex align-items-center p-0">
                    <i className="bx bx-envelope fs-xl text-primary me-2" />
                    example@email.com
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="nav-link d-flex align-items-center p-0">
                    <i className="bx bx-map fs-xl text-primary me-2" />
                    2464 Royal Ln. Mesa,New Jersey 45463
                  </a>
                </li>
              </ul>
              <div className="d-flex">
                <a href="#" className="btn btn-icon btn-outline-secondary btn-facebook me-3" aria-label="Facebook">
                  <i className="bx bxl-facebook" />
                </a>
                <a href="#" className="btn btn-icon btn-outline-secondary btn-twitter me-3" aria-label="Twitter">
                  <i className="bx bxl-twitter" />
                </a>
                <a href="#" className="btn btn-icon btn-outline-secondary btn-linkedin me-3" aria-label="LinkedIn">
                  <i className="bx bxl-linkedin" />
                </a>
                <a href="#" className="btn btn-icon btn-outline-secondary btn-instagram" aria-label="Instagram">
                  <i className="bx bxl-instagram" />
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 offset-xl-1">
              <div className="card border-0 shadow-sm p-sm-2">
                <form className="card-body needs-validation" noValidate>
                  <div className="mb-4">
                    <label htmlFor="service" className="form-label fs-base">Service</label>
                    <select id="service" className="form-select form-select-lg" required>
                      <option value selected disabled>Choose the service you are interested in</option>
                      <option value="Custom Software Development">Custom Software Development</option>
                      <option value="Software Integration">Software Integration</option>
                      <option value="Mobile App Development">Mobile App Development</option>
                      <option value="Business Analytics">Business Analytics</option>
                      <option value="Software Testing">Software Testing</option>
                      <option value="Project Management">Project Management</option>
                    </select>
                    <div className="invalid-feedback">Please choose the service!</div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="name" className="form-label fs-base">Full name</label>
                    <input type="text" id="name" className="form-control form-control-lg" required />
                    <div className="invalid-feedback">Please enter your name!</div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label fs-base">Email address</label>
                    <input type="email" id="email" className="form-control form-control-lg" required />
                    <div className="invalid-feedback">Please provide a valid email address!</div>
                  </div>
                  <div className="mb-4 pb-2">
                    <label htmlFor="message" className="form-label fs-base">Email address</label>
                    <textarea id="message" className="form-control form-control-lg" rows={3} placeholder="Tell us about your project" required defaultValue={""} />
                    <div className="invalid-feedback">Please enter you message!</div>
                  </div>
                  <button type="submit" className="btn btn-primary shadow-primary btn-lg">Send request</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default DevWeb;