import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavbarNav from "./NavbarNav";


function Header(props) {

    const navBars = props.navBar;

    const getStoredTheme = () => localStorage.getItem('theme');
    const setStoredTheme = theme => localStorage.setItem('theme', theme);

    const getPreferredTheme = () => {
    const storedTheme = getStoredTheme();
    if (storedTheme) {
        return storedTheme;
    }
    return 'light';
    };

    const setTheme = theme => {
    if (theme == 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
    } else {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }
    };

    const showActiveTheme = (theme) => {
    const themeSwitcher = document.querySelector('[data-bs-toggle="mode"]');
    const themeSwitcherCheck = themeSwitcher.querySelector('input[type="checkbox"]');

    if (!themeSwitcher) {
        return;
    }

    if (theme == 'dark') {
        themeSwitcherCheck.checked = true;
    } else {
        themeSwitcherCheck.checked = false;
    }
    };

    useEffect(() => {
        setTheme(getPreferredTheme());
        showActiveTheme(getPreferredTheme());

        const handleThemeChange = () => {
            const storedTheme = getStoredTheme();
            if (storedTheme !== 'light' && storedTheme !== 'dark') {
            setTheme(getPreferredTheme());
            }
        };

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleThemeChange);

        document.querySelectorAll('[data-bs-toggle="mode"]').forEach(toggle => {
            toggle.addEventListener('click', () => {
            const theme = toggle.querySelector('input[type="checkbox"]').checked ? 'dark' : 'light';
            setStoredTheme(theme);
            setTheme(theme);
            showActiveTheme(theme);
            });
        });

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleThemeChange);
        };
    }, []);

    if (navBars === "default") {

        return (
            <header class="header navbar navbar-expand-lg bg-light shadow-sm shadow-dark-mode-none fixed-top">
                <div className="container px-3">
                    <Link to="/" className="navbar-brand pe-3">
                        <img src="/asneclogo2.png" width={47} alt="Silicon" />
                        ASNEC-IT
                    </Link>

                    <NavbarNav />


                    <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
                    <input type="checkbox" className="form-check-input" id="theme-mode" />
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Lumière</label>
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Sombre</label>
                    </div>
                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                    </button>
                    
                </div>
            </header>
        );
        
    } else if(navBars === "AboutAndContact") {
        
        return (
            <header className="header navbar navbar-expand-lg position-absolute navbar-sticky">
                <div className="container px-3">
                    <Link to="/" className="navbar-brand pe-3">
                        <img src="/asneclogo2.png" width={47} alt="ASNECT-IT" />
                        ASNEC-IT
                    </Link>

                    <NavbarNav />

                    <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
                        <input type="checkbox" className="form-check-input" id="theme-mode" />
                        <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Lumière</label>
                        <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Sombre</label>
                    </div>

                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    
                </div>
            </header>
        );

    } else if (navBars === "Error404") {

        return (
            <header className="header navbar navbar-expand-lg fixed-top">
                <div className="container px-3">
                    <Link to="/" className="navbar-brand pe-3">
                        <img src="/asneclogo2.png" width={47} alt="Silicon" />
                        ASNEC-IT
                    </Link>


                    <NavbarNav />

                    <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
                    <input type="checkbox" className="form-check-input" id="theme-mode" />
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Lumière</label>
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Sombre</label>
                    </div>
                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                    </button>
                    
                </div>
            </header>

        );

    }  else if (navBars === "blog") {

        return (
            <header class="header navbar navbar-expand-lg bg-light shadow-sm shadow-dark-mode-none fixed-top">
                <div className="container px-3">
                    <Link to="/" className="navbar-brand pe-3">
                        <img src="/asneclogo2.png" width={47} alt="Silicon" />
                        ASNEC-IT
                    </Link>

                    <NavbarNav />


                    <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4" data-bs-toggle="mode">
                    <input type="checkbox" className="form-check-input" id="theme-mode" />
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Lumière</label>
                    <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode">Sombre</label>
                    </div>
                    <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                    </button>
                    
                </div>
            </header>

        );

    }

}

export default Header;