import { Link } from "react-router-dom";
import Header from "../../components/Header";


function Contact(props) {
    return (

      <>
        <Header navBar="default" />
        
        {/* Links + Contact form */}
        <section className="position-relative bg-white pt-5">
          <div className="container position-relative zindex-2 pt-5">
            {/* Breadcrumb */}
            <div className="row">
              {/* Contact links */}
              <div className="col-xl-4 col-lg-5 pb-4 pb-sm-5 mb-2 mb-sm-0">
                <div className="pe-lg-4 pe-xl-0">
                  <h1 className="pb-3 pb-md-4 mb-lg-5">Contactez-nous</h1>
                  <div className="d-flex align-items-start pb-3 mb-sm-1 mb-md-3">
                    <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                      <i className="bx bx-envelope" />
                    </div>
                    <div className="ps-3 ps-sm-4">
                      <h2 className="h4 pb-1 mb-2">Adresse email</h2>
                      <p className="mb-2">contact@asnec-it.com</p>
                      <p className="mb-2">servicesclients@asnec-it.com</p>
                      <div className="btn btn-link btn-lg px-0">
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                      <i className="bx bx-group" />
                    </div>
                    <div className="ps-3 ps-sm-4">
                      <h2 className="h4 pb-1 mb-2">Direction genérale</h2>
                      <p className="mb-2">14 Avenu Auxence ICKONGA, Ref. Ex Hôtel Ledger. République du Congo (Brazzaville)</p>
                      <div className="btn btn-link btn-lg px-0">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Contact form */}
              <div className="col-xl-6 col-lg-7 offset-xl-2">
                <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
                  <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block" />
                  <div className="card-body position-relative zindex-2">
                    <h2 className="card-title pb-3 mb-4">Dites-moi tout</h2>
                    <form className="row g-4 needs-validation" noValidate>
                      <div className="col-12">
                        <label htmlFor="fn" className="form-label fs-base">Nom complet</label>
                        <input type="text" className="form-control form-control-lg" id="fn" required />
                        <div className="invalid-feedback">Votre nom complet s'il vous plaît!</div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="email" className="form-label fs-base">Adresse email</label>
                        <input type="email" className="form-control form-control-lg" id="email" required />
                        <div className="invalid-feedback">Votre adresse email s'il vous plaît!</div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="specialist" className="form-label fs-base">Vous nous contactez pour ?</label>
                        <select className="form-select form-select-lg" id="specialist" required>
                          <option value selected disabled>Choisir l'objet</option>
                          <option value="Therapist">Demandez un devis</option>
                          <option value="Dentist">Heberger une application</option>
                          <option value="Cardiologist">Developpement d'une application</option>
                          <option value="Pediatrician">Couverture evenementiel</option>
                          <option value="Gynecologist">Spot publicitaire</option>
                          <option value="Surgeon">Demande d'information</option>
                          <option value="Surgeon">Autres</option>
                        </select>
                        <div className="invalid-feedback">Vous nous contactez pour ?</div>
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="date" className="form-label fs-base">Description</label>
                        <textarea name="" id="" cols="10" rows="5" className="form-control form-control-lg">
                        </textarea>
                      </div>
                      <div className="col-12 pt-2 pt-sm-3">
                        <button type="submit" className="btn btn-lg btn-primary w-100 w-sm-auto">Soumettre</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute bottom-0 start-0 w-100 bg-light" style={{height: '8rem'}} />
        </section>

        {/* Branches */}
        <section className="container py-2 py-lg-4 py-xl-5 mb-2 mb-md-3">
          <div className="row py-5">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="d-flex flex-column h-100 shadow-sm rounded-3 overflow-hidden">
                <iframe className="d-block h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63660.635623370385!2d15.21023549772808!3d-4.261095152423407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a6a32ac441bb83b%3A0xab3deababe7de443!2sBrazzaville!5e0!3m2!1sfr!2scg!4v1712719418786!5m2!1sfr!2scg" style={{border: 0, minHeight: 300}} allowFullScreen loading="lazy" title="Map" />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <h2 className="h4 mb-4">Brazzaville</h2>
              <ul className="list-unstyled pb-2 pb-lg-0 mb-4 mb-lg-5">
                <li className="d-flex pb-1 mb-2">
                  <i className="bx bx-map text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  14 Avenu Auxence ICKONGA, Ref. Ex Hôtel Ledger
                </li>
                <li className="d-flex pb-1 mb-2">
                  <i className="bx bx-phone-call text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  (+242) 06 685 70 57 / 05 057 88 18
                </li>
                <li className="d-flex">
                  <i className="bx bx-time-five text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  <div>
                    <strong className="text-nav">Lundi - Vendre</strong>: 08h30 - 17h30
                    <br />
                    <strong className="text-nav">Samedi</strong>: 08h30 - 15h00
                  </div>
                </li>
              </ul>
              <h2 className="h4 mb-4">Pointe Noire</h2>
              <ul className="list-unstyled pb-2 pb-lg-0 mb-4 mb-lg-5">
                <li className="d-flex pb-1 mb-2">
                  <i className="bx bx-map text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  Avenue. Ref. Rond point Siafoumou
                </li>
                <li className="d-flex pb-1 mb-2">
                  <i className="bx bx-phone-call text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  (+242) 06 685 70 57 / 05 057 88 18
                </li>
                <li className="d-flex">
                  <i className="bx bx-time-five text-primary fs-xl me-2" style={{marginTop: '.125rem'}} />
                  <div>
                    <strong className="text-nav">Mon - Fri</strong>: 08h30 - 17h30
                    <br />
                    <strong className="text-nav">Samedi:</strong>: 08h30 - 15h00
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>

      </>
      
    );
}

export default Contact;