import React from 'react';

function Faq(props) {
    return (
      
      Faq

    );
}

export default Faq;